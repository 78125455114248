import { Vhcmodule } from "../vhcmodules/vhcmodule";
import { AuthObject } from "./authobject.interface";
import { jlocation } from "./jlocation.interface";

export interface vhcrecord {
    id?: number,
    iqjc_id?: string,

    created_at?: string // time,
    isdone?: boolean,

    blueprintname: string,
    vin: string,
    description: string,
    disclaimer: string,
    disclaimer2: string,
    fixedansweringorder: boolean,

    includepre: boolean,
    includempi: boolean,
    includejp: boolean,
    includeauth: boolean,
    includeqi: boolean,

    completedpre?: string // time,
    completedprejp?: string // time,
    completedpreauth?: string // time,
    completedmpi?: string // time,
    completedjp?: string // time,
    completedauth?: string // time,
    completedqi?: string // time,

    assignees?: any,

    premodules: Vhcmodule[],
    mpimodules: Vhcmodule[],
    qimodules: Vhcmodule[],

    status: string,

    authobjects: AuthObject[]

    portalstatus?: number;
    portalpass?: string;
    portalsettings?: PortalSettings;
    jlocation_code: string|jlocation;

    handover?: string;

    organisationdata: OrganisationData;

    mileage: number;
}

export interface OrganisationData {
    connectedToDms: boolean,
    requestedpreitems: boolean,
    gotpreitems: boolean,
    requestedmpiitems: boolean,
    gotmpiitems: boolean,
    iqmjc_id: string,
    appointmentref: number,
    serviceadvisor_id: string,
    prepostresult: any
}

export enum VHCSTATUS {
    new = "new",
    pre = "pre",
    mpi = "mpi",
    jp = "jp",
    auth = "auth",
    qi = "qi",
    fin = "fin"
} 

export interface PortalSettings {
    expectedHours: number;
    expectedMinutes: number;
    pickupHour: number;
    pickupMinutes: number;
    includeSunday: boolean;
    includeMonday: boolean;
    includeTuesday: boolean;
    includeWednesday: boolean;
    includeThursday: boolean;
    includeFriday: boolean;
    includeSaturday: boolean;
}