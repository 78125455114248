/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { AuthChangeEvent, createClient, RealtimeSubscription, Session, SupabaseClient, UserCredentials } from '@supabase/supabase-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { VhcMessage } from '../pages/messages/messages.component';

@Injectable({
  providedIn: 'root'
})
export class SupaService {

  public supabase: SupabaseClient;
  public LoginObject: BehaviorSubject<UserPermissions|undefined> = new BehaviorSubject<UserPermissions|undefined>(undefined);
  public NotificationObservable: Subject<VhcMessage> = new Subject<VhcMessage>();
  public UnreadNotificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private toastController: ToastController, private router: Router) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
    this.authChanges(async (_, session) => {
      console.log(_, session, 'from auth change observer');

      if (_ === 'SIGNED_OUT') {
        document.title = 'IQ | VHC';
        this.LoginObject.next(undefined);
        return;
      }
      this.refreshPermissions(session);
    });
  }

  public async refreshPermissions(session) {

    // Check for data
    if (!session) {
      this.LoginObject.next(undefined);
      return this.signOut();
    }
    if (!session?.user) {
      this.LoginObject.next(undefined);
      return this.signOut();
    }
    if (!session?.user?.user_metadata) {
      this.LoginObject.next(undefined);
      return this.signOut();
    }
    if (!session?.user?.user_metadata.provider_id) {
      this.LoginObject.next(undefined);
      return this.signOut();
    }

    // Get permissions
    const provider_id = session?.user?.user_metadata.provider_id;
    const userdataresult = await this.supabase.from<UserPermissions>('userpermissions').select('*').eq('id', provider_id).single();
    if (!userdataresult.data?.permissions.includes('VhcCanOpen')) {
      this.LoginObject.next(undefined);
      return this.signOut();
    }

    if (!userdataresult.data) {
      this.LoginObject.next(undefined);
      return this.signOut();
    }

    // Set & Return data
    document.title = 'IQ | VHC' + ((session?.user?.email)?', ' +(session?.user?.email):'');
    console.log(userdataresult.data);
    if (JSON.stringify(this.LoginObject.value) !== JSON.stringify(userdataresult.data)) {
      this.LoginObject.next(userdataresult.data);
    } else {
      console.log('Same permissions');
    }
    this.initNotifications();
  }

  notifySubscription: RealtimeSubscription;
  private initNotifications() {
    console.log('Init Notification Subscription');
    if (this.notifySubscription) {
      this.notifySubscription.unsubscribe();
    }
    this.countUnreadMessages();
    this.notifySubscription = this.supabase.from('vhcmessages:userid=eq.'+this.LoginObject.value?.id+'').on('INSERT', payload => {
      this.NotificationObservable.next(payload.new);
      this.UnreadNotificationCount.next(this.UnreadNotificationCount.value+1);
      this.presentNotification(payload.new);
    }).subscribe();
  }

  async presentNotification(message: VhcMessage) {
    console.log(message);
    const toast = await this.toastController.create({
      animated: true,
      buttons: [
        {
          side: 'end',
          role: 'cancel',
          icon: message.url.length ? 'send' : 'close',
          handler: async () => {
            message.isread = true;
            if (message.url) {
              this.router.navigateByUrl(message.url);
            }
            await this.supabase.from('vhcmessages').update({isread: true}).eq('id', message.id);
            this.countUnreadMessages();
          }
        }
      ],
      color: 'primary',
      duration: 7000,
      header: 'Notification',
      keyboardClose: true,
      message: message.message.length > 50 ? message.message.substring(0, 50)+'...' : message.message,
      mode: 'md',
      position: 'top'
    });
    toast.present();
  }

  public countUnreadMessages() {
    this.supabase.from('vhcmessages').select('id', { count: 'exact', head: true }).eq('userid',this.LoginObject.value?.id+'').eq('isread', false).then(v => {
      const c = v.count ? v.count : 0;
      this.UnreadNotificationCount.next(c);
    });
  }

  public get user() {
    return this.supabase.auth.user();
  }

  public get session() {
    return this.supabase.auth.session();
  }

  private authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  signIn(credentials: UserCredentials) {
    return this.supabase.auth.signIn(credentials, {redirectTo: 'http://localhost:8100/'});
  }

  public signOut() {
    document.title = 'IQ | VHC';
    this.LoginObject.next(undefined);
    return this.supabase.auth.signOut();
  }

  public async ExternalConfig(): Promise<any> {
    const settings = await this.Settings();
    if (localStorage.getItem('EXTCONFEXPIRETIME') == null) {
      console.log('Removing external config because no expiretime was found');
      localStorage.removeItem('EXTCONF');
    } else if (Number.parseInt(localStorage.getItem('SETTINGSEXPIRETIME')+'', 10) < Date.now()) {
      console.log('Removing external config because they are expired');
      localStorage.removeItem('EXTCONF');
    }

    if (localStorage.getItem('EXTCONF') == null) {
      localStorage.setItem('EXTCONFEXPIRETIME', (Date.now() + environment.SettingsExpiretime).toString());
      const v = await this.supabase.from('externalconfig').select('*', { count: 'exact' }).eq('id', settings.externalconfig).single();
      if (!v.data) {
        return {};
      } else {
        // continue normal work
        localStorage.setItem('EXTCONF', JSON.stringify(v.data));
        return v.data;
      }
    } else {
      console.log('External config served from cache');
      const exco = JSON.parse(localStorage.getItem('EXTCONF')+'');
      return exco;
    }
  }

  // Responsible for applications dynamic settings.
  public async Settings(): Promise<JSettings> {
    if (localStorage.getItem('SETTINGSEXPIRETIME') == null) {
      console.log('Removing settings because no expiretime was found');
      localStorage.removeItem('SETTINGS');
    } else if (Number.parseInt(localStorage.getItem('SETTINGSEXPIRETIME')+'', 10) < Date.now()) {
      console.log('Removing settings because they are expired');
      localStorage.removeItem('SETTINGS');
    }

    if (localStorage.getItem('SETTINGS') == null) {
      localStorage.setItem('SETTINGSEXPIRETIME', (Date.now() + environment.SettingsExpiretime).toString());
      const v = await this.supabase.from('settings').select('*', { count: 'exact' });
      if (v.count == null || v.count === 0) {
        this.signOut();
        console.log('There are no settings');
        return;
      } else {
        // continue normal work
        const settingsObject: JSettings = {
          id: v.data[0].id,
          reddays: v.data[0].reddays,
          amberdays: v.data[0].amberdays,
          lang1name: v.data[0].lang1name,
          lang1code: v.data[0].lang1code,
          lang2enabled: v.data[0].lang2enabled,
          lang2name: v.data[0].lang2name,
          lang2code: v.data[0].lang2code,
          lang1currency: v.data[0].lang1currency,
          lang2currency: v.data[0].lang2currency,
          defaulttax: v.data[0].defaulttax,
          videocalls: v.data[0].videocalls,
          externalconfig: v.data[0].externalconfig
        };
        localStorage.setItem('SETTINGS', JSON.stringify(settingsObject));
        return settingsObject;
      }
    } else {
      console.log('Settings served from cache');
      const sob = JSON.parse(localStorage.getItem('SETTINGS')+'');
      sob.lang2enabled = (String(sob.lang2enabled).toLowerCase() === 'true');
      return sob;
    }
  }

  // Add and plan tasks in IQPlanning
  public async IQPAddTasksAndPlan(url, dmsid, taskslist: IQTask[], replace=false) {
    const body = {
      token: '8beb509c-a597-49dc-bc92-dd441d6f2a21-5412b3d3-9563-4549-ae14-8358b0b4f099',
      dmsid,
      tasklist: taskslist,
      replaceTasks: replace
    };
    return this.http.post(url, body).toPromise();
  }

  // Code for easy user mapping
  userListCache=new Map<string,{fullname: string; employee_id: string}>();
  userListCacheDate=0;
  public async getUserList(): Promise<Map<string,{fullname: string; employee_id: string}>> {
    const now = new Date().getTime();
    if (now - this.userListCacheDate < 60000) {
      console.log('Loaded users from cache');
      return this.userListCache;
    }
    console.log('Loading new user list');
    const {data,error} = await this.supabase.from('userpermissions').select('id,fullname,employee_id');
    if (error) {
      console.error(error);
    } else {
      // eslint-disable-next-line prefer-const
      let _userListCache=new Map<string,{fullname: string; employee_id: string}>();
      data.forEach(u => {
        _userListCache.set(u.id, {fullname: u.fullname, employee_id: u.employee_id});
      });
      this.userListCache = _userListCache;
      this.userListCacheDate = now;
      return this.userListCache;
    }
  }
}

export interface UserPermissions {
  email: string;
  fullname: string;
  id: string;
  permissions: string[];
  employee_id: string;
  updated_at: Date;
}

export interface IQTask {
  Duration: number;
  Order: string;
  Name: string;
  Type: string;
  Employabilities: number[];
  BaseId: string;
  Team: boolean;
}

export interface JSettings {
  id?: number;
  reddays: number;
  amberdays: number;
  lang1name: string;
  lang1code: string;
  lang1currency: string;
  lang2enabled: boolean;
  lang2name: string;
  lang2code: string;
  lang2currency: string;
  defaulttax: number;
  externalconfig: number;
  videocalls: string;
}

export interface IQWorkOrderData {
  appointmentRef: string; // Only relevant the first PUT because it will convert our dummy to a real job card
  dmsId: string;
  masterJC: string;
  woType: string;
  description: string;
  remarks: string;
  vehicleId: string; // VIN
  licensePlate: string;
  engineCode: string;
  mileage: number;
  carType: string;
  yearOfBuild: number;
  preferedMechanic: string;
  preferedReceptionist: string;
  customerCity: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  tasks: IQWorkOrderDataJob[];
}

export interface IQWorkOrderDataJob {
  duration: number; // In minutes
  code: string;
  name: string;
}
