/* eslint-disable max-len */
import { RecommendationEditDialog } from "../../components/recommendationdialog/recommendationedit.dialog";
import { HelperService } from "../../services/helper.service";
import { brakediscpad } from "../../interfaces/brakediscpad.interface";
import { SupaService } from "../../services/supa.service";
import { Vhcmodule } from "../vhcmodule";
import { BrakeanswerDialog } from "./brakeanswer/brakeanswer.dialog";
import { Job } from "../../interfaces/job.interface";
import { Part } from "../../interfaces/part.interface";

export class VhcBrakeModule extends Vhcmodule
{
    includeinsignature = false
    recommendations:string[][] = []
    // data: BrakeAnswerData = {
    //     brakes: [
    //       {name: "FL", value: 0, discmm: undefined, padmm: undefined, discpadid:undefined, images: [], recommendation: "", recommendation2: "", dateAuthorised: '',jobs:[],parts:[],price:0},
    //       {name: "FR", value: 0, discmm: undefined, padmm: undefined, discpadid:undefined, images: [], recommendation: "", recommendation2: "", dateAuthorised: '',jobs:[],parts:[],price:0},
    //       {name: "RL", value: 0, discmm: undefined, padmm: undefined, discpadid:undefined, images: [], recommendation: "", recommendation2: "", dateAuthorised: '',jobs:[],parts:[],price:0},
    //       {name: "RR", value: 0, discmm: undefined, padmm: undefined, discpadid:undefined, images: [], recommendation: "", recommendation2: "", dateAuthorised: '',jobs:[],parts:[],price:0},
    //     ]
    // }
    data: BrakeAnswerData = {
        brakes: [
          {name: "Front", value: 0, discmm: undefined, padmm: undefined, discpadid:undefined, images: [], recommendation: "", recommendation2: "", dateAuthorised: '',jobs:[],parts:[],price:0},
          {name: "Rear", value: 0, discmm: undefined, padmm: undefined, discpadid:undefined, images: [], recommendation: "", recommendation2: "", dateAuthorised: '',jobs:[],parts:[],price:0},
        ]
    }

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = "Brakes";
        this.name = "Inspect brakes";
    }

    async openAnswer(brakediscpads: brakediscpad[]) {
        await this.loadSettings();
        let ans = await this.helper.createAndOpenModal(BrakeanswerDialog, {
            brakemodule: this,
            brakediscpads: brakediscpads,
            settings: this.settings,
        }, 'doubled-modal tall-modal');
        return !(ans.role == 'backdrop' || ans.role == 'cancel')
    }

    getName() {
        return this.name
    }

    getQIcon(v:any=undefined) {
        let lowest = 4;
        for (let i = 0; i < this.data.brakes.length; i++)
        {
            if (this.data.brakes[i].value < lowest) {
                lowest = this.data.brakes[i].value
            }
        }
        if (lowest == 3) {
            return 'green-text'
        }
        if (lowest == 1) {
            return 'red-text'
        }
        if (lowest == 2) {
            return 'amber-text'
        }
        return 'grey-text'
    }

    public async openEditor(): Promise<void> {
        await this.loadSettings();

        let prop = {
            recommendations: this.recommendations,
            text: this.name,
            text2: this.name2
        }
        const modal = await this.helper.createAndOpenModal(RecommendationEditDialog, {
            prop: prop,
            settings: this.settings
        })

        if (modal.data) {
            if (modal.data.prop) {
                this.name = modal.data.prop.text;
                this.name2 = modal.data.prop.text2;
                this.recommendations = modal.data.prop.recommendations;
            }
        }
    }

    getPdfVersion(param:any=undefined,primarylang=false) {
        let d = {
            table: {
                headerRows: 1,
                widths: [120, 15, 15, 15, 15, 120, 55, 25],
                body: [
                    [{ text: 'Brakes', fillColor: "black", color: "white", bold: true }, { text: '', fillColor: "#02a786" }, { text: '', fillColor: "#fcf002" }, { text: '', fillColor: "#e22426" }, { text: '', fillColor: "#efefef" }, 'Disc thickness', primarylang ? 'Fitted price' : "السعر", primarylang ? 'Auth' : 'التعميد'],
                    ['Left front', { text: '', fillColor: "#02a786" }, { text: '', fillColor: "#fcf002" }, { text: '', fillColor: "#e22426" }, { text: '', fillColor: "#efefef" }, '', '', ''],
                    ['Right front', { text: '', fillColor: "#02a786" }, { text: '', fillColor: "#fcf002" }, { text: '', fillColor: "#e22426" }, { text: '', fillColor: "#efefef" }, '', '', ''],
                    ['Left rear', { text: '', fillColor: "#02a786" }, { text: '', fillColor: "#fcf002" }, { text: '', fillColor: "#e22426" }, { text: '', fillColor: "#efefef" }, '', '', ''],
                    ['Right rear', { text: '', fillColor: "#02a786" }, { text: '', fillColor: "#fcf002" }, { text: '', fillColor: "#e22426" }, { text: '', fillColor: "#efefef" }, '', '', ''],
                    ['Spare tyre', { text: '', fillColor: "#02a786" }, { text: '', fillColor: "#fcf002" }, { text: '', fillColor: "#e22426" }, { text: '', fillColor: "#efefef" }, '', '', '']
                ]
            },
            margin: [0, 0, 0, 20]
        };
        return d
    }

    getHtmlVersion(param:any=false,primarylang=false) {
        let htmlBody = `<table class="striped" style="border: 1px solid #d2d6dd;">`;
        htmlBody += `
        <thead><tr>
            <th width="330">${primarylang ? this.name : this.name2}</th>
            <th width="25">-</th>
            <th>${primarylang ? 'Report & recommendations' : 'التقارير و التوصيات'}</th>
            <th width="90">${primarylang ? 'Price' : "السعر"}</th>
            <th width="50">${primarylang ? 'Auth' : 'التعميد'}</th>
        </tr></thead><tbody>
        `;
        let atleastone = false;
        this.data.brakes.forEach((t,i) => {
            if (t.dateAuthorised || param === false) {
                atleastone = true
                htmlBody += `
                <tr style="border-top: 1px solid #d2d6dd;">
                    <td>
                        ${t.name}
                    </td>
                    <td style="background-color: #${t.value==3 ? '02a786' : (t.value == 2 ? 'fcf002' : (t.value == 1 ? 'e22426' : '607d8b'))};"></td>
                    <td>${primarylang ? t.recommendation : t.recommendation2}</td>
                    <td style="border-left: 1px solid #607d8b;border-right: 1px solid #607d8b;text-align: right" class="price">${this.helper.tax(t.price, 0)}</td>
                    <td>${t.dateAuthorised?'🔑':''}</td>
                </tr>
                `;
            }
        })
        htmlBody += `</tbody></table><br />`;
        return atleastone ? htmlBody : '';;
    }

    public toDatabase(includedata=false) {
        if (includedata) {
            return {name: this.name, name2: this.name2, type: this.type, recommendations: this.recommendations, data: this.data}
        } else {
            return {name: this.name, name2: this.name2, type: this.type, recommendations: this.recommendations}
        }
    }
    
    public fromDatabase(obj, includedata=false) {
        this.name = obj.name
        this.name2 = obj.name2
        this.recommendations = obj.recommendations
        if (includedata) {
            if (obj.hasOwnProperty('data')) {
                this.data = obj.data
            }
        }
    }
}

export interface BrakeAnswerData {
    brakes: BrakeAnswer[],
    portalid?: string,
}

export interface BrakeAnswer {
    name: string;
    value: number;
    discpadid: number;
    discmm: number;
    padmm: number;
    dateAuthorised: string;
    images: string[];
    recommendation: string;
    recommendation2?: string;
    portalid?: string;
    auth?:boolean;
    jobs: Job[];
    parts: Part[];
    price: number;
}