import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { brakediscpad } from '../../../interfaces/brakediscpad.interface';
import { Tyrebrand } from '../../../interfaces/tyrebrand.interface';
import { HelperService } from '../../../services/helper.service';
import { Vhcmodule } from '../../../vhcmodules/vhcmodule';

@Component({
  selector: 'app-prescreen',
  templateUrl: './prescreen.dialog.html',
  styleUrls: ['./prescreen.dialog.scss'],
})
export class PrescreenDialog implements OnInit {

  @Input('description') description: string;
  @Input('premodules') premodules: Vhcmodule[];
  @Input('completed') completed: string;
  @Input('tyrebrands') tyrebrands: Tyrebrand[];
  @Input('brakediscpads') brakediscpads: brakediscpad[];

  constructor(private modalController: ModalController, private helper: HelperService) { }

  ngOnInit() {
  }

  @ConfirmDelete()
  removeCSell(module, i) {
    module.data.splice(i,1);
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  saveModal() {
    this.modalController.dismiss({savedata: true});
  }

  async openQuestion(midx: number, qindex: number) {
    if (qindex == 0) {
      this.helper.notify('Opening questionblock ' + this.premodules[midx].name);
    }
    const result = await this.premodules[midx].openAnswer(qindex);
    if (result != undefined) {
      if (this.premodules[midx].questions.length > qindex+1) {
        this.openQuestion(midx, qindex+1);
      } else {
        this.openModule(midx+1);
      }
    }
  }

  async openModule(midx,wasauto=false) {
    if (midx >= this.premodules.length) {
      this.helper.notify('You have reached the end of the list');
      return;
    }
    if (this.premodules[midx].type == 'Cross_sell' && wasauto) {
      this.helper.notify('You have reached the sales module');
      return;
    }
    if (this.premodules[midx].type == 'Instruction') {
      this.helper.notify('instruction: ' + this.premodules[midx].name);
      this.openModule(midx+1);
      return;
    }
    if (this.premodules[midx].type == 'Questionblock') {
      this.openQuestion(midx, 0);
      return;
    }

    let ans;
    if (this.premodules[midx].type == 'Tyres') {
      ans = await this.premodules[midx].openAnswer(this.tyrebrands);
    } else if (this.premodules[midx].type == 'Brakes') {
      ans = await this.premodules[midx].openAnswer(this.brakediscpads);
    } else {
      ans = await this.premodules[midx].openAnswer();
    }
    if (ans) {
      this.openModule(midx+1,true);
    }
  }

  complete() {
    this.helper.promptYesNo('Do you want to complete the pre inspection?', 'The pre inspection will be locked and the record will be saved', ()=>{
      this.modalController.dismiss({completestep: true});
    }, 'Complete step', 'Cancel');
  }

}
