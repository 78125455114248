import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { DateTime } from 'luxon';
import { Vehicle } from '../interfaces/vehicle.interface';
import { vhcrecord } from '../interfaces/vhcrecord.interface';
import { JobPartscreenDialog } from '../pages/vhcrecord/jobpartscreen/jobpartscreen.dialog';
import { HelperService } from './helper.service';
import { IQWorkOrderData, SupaService } from './supa.service';
import { Part } from '../interfaces/part.interface';

@Injectable({
  providedIn: 'root'
})
export class DmsService {

  constructor(private http: HttpClient, private loadingController: LoadingController, private helper: HelperService, private supa: SupaService) {
  }

  public async getDMS_VEHICLE(vin:string) {
    const loading = await this.loadingController.create({
      message: 'Checking if the vehicle is the DMS',
      spinner: 'bubbles',
      backdropDismiss: true
    });
    await loading.present();

    try {
      let result = await this.http.get<DmsCarApiResult>("https://m.hha.com.sa/ords/hhaapi/osb/cardata/?vin="+vin, {
        headers: {
          Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
        }
      }).toPromise();
      loading.dismiss();
      return result;
    } catch (error) {
      loading.dismiss();
      return {items:[]}
    }
  }

  // public async sendDMS_PRE_ITEMS(jobs:DmsTask[], record: vhcrecord, vehicle: Vehicle):Promise<DmsCreateJobcardResult> {
  //   const _ = (v:string):string=>{return v ? v : ""};
  //   const loading = await this.loadingController.create({
  //     message: 'Creating job card in the DMS',
  //     spinner: 'bubbles',
  //     backdropDismiss: true
  //   });
  //   await loading.present();

  //   let HHAPayload:any = {
  //     AppointmentRef    : record.organisationdata.appointmentref ? Math.floor(record.organisationdata.appointmentref/1000) : 0,
  //     VhcId             : record.id,
  //     LocationCode      : record.jlocation_code,
  //     ServiceAdvisorId  : _(record.organisationdata.serviceadvisor_id),
  //     Vin               : _(record.vin),
  //     Plate_no          : _(vehicle.licenseplate),
  //     PhoneNumber       : vehicle.communicatewithowner ? _(vehicle.ownerphone) : _(vehicle.driverphone),
  //     DriverName        : _(vehicle.drivername),
  //     DriverEmail       : _(vehicle.drivermail),
  //     DriverPhone       : _(vehicle.driverphone),
  //     OwnerName         : _(vehicle.ownername),
  //     OwnerEmail        : _(vehicle.ownermail),
  //     OwnerPhone        : _(vehicle.ownerphone),
  //     ContactOwner      : vehicle.communicatewithowner,
  //     IsNewCustomerInfo : false,
  //     kms               : 0
  //   }

  //   let service = jobs.filter(j => j.ItemName.includes('KM Service'));
  //   if (service.length > 0) {
  //     HHAPayload.ServiceItems = [service[0]];
  //     HHAPayload.kms = Number.parseInt(service[0].ItemName.split('KM Service')[0]);
  //   }

  //   let repairs = jobs.filter(j => !j.ItemName.includes('KM Service'));
  //   if (repairs.length > 0) {
  //     HHAPayload.RepairItems = repairs;
  //   }

  //   if (repairs.length == 0 && service.length == 0) {
  //     loading.dismiss();
  //     return {response:[]};
  //   }

  //   // Send to HHA
  //   let fdat = new FormData();
  //   fdat.set('payload', JSON.stringify(HHAPayload))
  //   let result = await this.http.post<DmsCreateJobcardResult>("https://m.hha.com.sa/ords/hhaapi/osb/Contact_job/", fdat, {
  //     headers: {
  //       Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
  //     }
  //   }).toPromise();

  //   await this.helper.saveToLog("sendDMS_PRE_ITEMS " + HHAPayload.VhcId, JSON.stringify(HHAPayload))
  //   console.log(result?.response?.length > 0 , record.jlocation_code.toString().length > 1, 'CompleteInIqp?');
  //   if (result?.response?.length > 0 && record.jlocation_code.toString().length > 1) {       
  //     let payload: IQWorkOrderData = {
  //       appointmentRef: record.organisationdata.appointmentref ? record.organisationdata.appointmentref.toString() : "0",
  //       dmsId: result.response[0].master_job_no,
  //       masterJC: result.response[0].master_job_no,
  //       woType: 'MASTER',
  //       description: '',
  //       remarks: '',
  //       vehicleId: vehicle.vin,
  //       licensePlate: vehicle.licenseplate,
  //       engineCode: vehicle.enginecode,
  //       mileage: 0,
  //       carType: vehicle.cartype,
  //       yearOfBuild: vehicle.yearofbuild,
  //       preferedMechanic: '',
  //       preferedReceptionist: '',
  //       customerCity: '',
  //       customerName: vehicle.communicatewithowner ? vehicle.ownername : vehicle.drivername,
  //       customerAddress: vehicle.communicatewithowner ? vehicle.ownermail : vehicle.drivermail,
  //       customerPhone: vehicle.communicatewithowner ? vehicle.ownerphone : vehicle.driverphone,
  //       tasks: []
  //     }
  //     service.forEach(r => {
  //       payload.tasks.push({
  //         duration: r.duration,
  //         code: r.code,
  //         name: r.ItemName
  //       })
  //     })
  //     repairs.forEach(r => {
  //       payload.tasks.push({
  //         duration: r.duration,
  //         code: r.code,
  //         name: r.ItemName
  //       })
  //     })
  //     console.log('Putting to IQ', payload);
  //     await this.supa.supabase.rpc('post_jobcard_to_iq', {
  //       payload: payload,
  //       locationcode: record.jlocation_code
  //     }).single()
  //     record.iqjc_id = result.response[0].master_job_no
  //   }
  //   loading.dismiss();
  //   return result;
  // }

  // public async receiveDMS_MPI_ITEMS(job_no) {
  //   const loading = await this.loadingController.create({
  //     message: 'Getting jobs from DMS ('+job_no+')',
  //     spinner: 'bubbles'
  //   });
  //   await loading.present();

  //   let result = await this.http.get("https://m.hha.com.sa/ords/hhaapi/osb/WORK_JOB_DETAILS_EST/?job_number="+job_no, {
  //     headers: {
  //       Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
  //     }
  //   }).toPromise();

  //   loading.dismiss();
  //   return result;
  // }

  // public async receiveDMS_MPI_PARTS(job_no) {
  //   const loading = await this.loadingController.create({
  //     message: 'Getting parts from DMS ('+job_no+')',
  //     spinner: 'bubbles'
  //   });
  //   await loading.present();

  //   let result = await this.http.get("https://m.hha.com.sa/ords/hhaapi/osb/WORK_JOB_REQS/?job_number="+job_no+'&approved=A', {
  //     headers: {
  //       Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
  //     }
  //   }).toPromise();

  //   loading.dismiss();
  //   return result;
  // }

  // public async sendDMS_MPI_ITEMS(vin, mjcid, jcid, vhcid, modules):Promise<DmsUpdateJobcardResult> {

  //   // Present laoder
  //   const loading = await this.loadingController.create({
  //     message: 'Synchronising data with DMS',
  //     spinner: 'bubbles',
  //     duration: 2000
  //   });
  //   await loading.present();

  //   // Build payload
  //   let repairitems = JobPartscreenDialog.ConvertToItems(modules);

  //   let HHAPayload = {
  //     "MasterJcId": Number.parseInt(mjcid),
  //     "RepairJcId": Number.parseInt(jcid),
  //     "VhcId": vhcid,
  //     "Vin": vin,
  //     "RepairItems": repairitems
  //   }

  //   // Send to HHA
  //   let fdat = new FormData();
  //   fdat.set('payload', JSON.stringify(HHAPayload))
  //   let result = await this.http.post<DmsUpdateJobcardResult>("https://m.hha.com.sa/ords/hhaapi/osb/Contact_Job_Update/", fdat, {
  //     headers: {
  //       Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
  //     }
  //   }).toPromise();

  //   //{"response":[{"job_no":"214600313","status":"200"}]}
  //   // Save in our DB
    
  //   await this.helper.saveToLog("sendDMS_MPI_ITEMS " + HHAPayload.VhcId, JSON.stringify(HHAPayload))
  //   loading.dismiss();
  //   return result;
  // }

  // public async sendSms(phonenumber: string, message: string) {
  //   const loading = await this.loadingController.create({
  //     message: 'Sending sms',
  //     spinner: 'bubbles'
  //   });
  //   await loading.present();
  //   // let result = await this.http.post('https://mtest.hha.com.sa/ords/hha/api/sendSMS/', { //0509490486
  //   let result = await this.http.post('https://m.hha.com.sa/ords/hhaapi/osb/sendSMS/', {
  //     mobileno: phonenumber,
  //     smsText: message
  //   }, {
  //     headers: {
  //       Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
  //     }
  //   }).toPromise();
  //   loading.dismiss();
  //   return result;
  // }

  public async sendIqSms(phonenumber: string, message: string) {
    // if (!phonenumber.startsWith('+31')) {
    //   return
    // }
    const loading = await this.loadingController.create({
      message: 'Sending sms',
      spinner: 'bubbles'
    });
    await loading.present();
    await this.http.post('https://rest.messagebird.com/messages', {
      originator: '+31502111893',
      recipients: phonenumber,
      body: message
    }, {
      headers: {
        Authorization: 'AccessKey KBqUZYKWSsd6wIZbrc0F0d0iM'
      }
    }).toPromise()

    loading.dismiss();
  }

  public GetNowTime() {
    return DateTime.local().setZone("UTC").toSeconds()
  }

}

export interface DmsTask {
  ItemID: string,
  ItemName: string,
  Remarks1: string,
  Remarks2: string,
  Color: string,
  duration?: number,
  code?: string,
  parts?: Part[]
}

export interface DmsCreateJobcardResult {
  response: DmsCreateJobcardResultValue[]
}

export interface DmsCreateJobcardResultValue {
  master_job_no: string;
  job_no_new: string;
  status: string;
  job_details: "S"|"R"
}

export interface DmsUpdateJobcardResult {
  response: DmsUpdateJobcardResultValue[]
}

export interface DmsUpdateJobcardResultValue {
  job_no: string;
  status: string;
}

export interface DmsCarApiResult {
  items: DmsCar[]
}

export interface DmsCar {
  customer_name: string;
  vin: string;
  phonenumber: string;
  brand: string;
  model: string;
  licenseplate: string;
  model_year: string;
  model_group: string;
  vehicle_model_name: string;
  vehicle_model_code: number;
  color: string;
  color_code: string;
  is_recalled: number;
  last_service_km: number;
}