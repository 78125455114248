export const sid = "rwoxbpgxrcjbntqwcxvp";
export const environment = {
  production: true,
  supabaseUrl: 'https://' + sid + '.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ3b3hicGd4cmNqYm50cXdjeHZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjU2NjIwMjksImV4cCI6MTk4MTIzODAyOX0.iq-xOeIYy-yKVpJqfw_Hf_uGyusXRJWIvKJMz6r9USQ',
  vehicleVisualsUrlNoPartId: 'https://iqmotive.vehiclevisuals.com/thirdpartyapi/#!/viewAnimation/PARTNUMBERHERE?show_menu=0&is_interactive=0&show_left_sidebar=0&show_description=0&video_only=1&auto_play=0',
  SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
  logoUrl: 'https://alcrveshtambcsaxrded.supabase.co/storage/v1/object/public/demofiles/VHCLogo.png?a=1682274070319',
  osbUrl: 'https://osb.release.iq-motive.com',
  osbFrontendUrl: 'https://osb.release.iq-motive.com',
  internalfileUrl: 'https://' + sid + '.supabase.co/storage/v1/object/public/internaldesign/',
  portalUrl: '',
};


// export const vw_environment = {
//   production: false,
//   logoUrl: 'https://oxqusnbpamaqppeadzex.supabase.co/storage/v1/object/public/internaldesign/companylogo.png',
//   osbUrl: 'https://hhaosb.iq-motive.com',
//   portalUrl: '',
//   supabaseUrl: 'https://oxqusnbpamaqppeadzex.supabase.co',
//   supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyMjYzNTM1NiwiZXhwIjoxOTM4MjExMzU2fQ.LeBqbiPi72bY091l-Sne2RFeHCOeT5v7MShUrDhUpt8',
//   SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
//   currentVersion: 3
// };

// export const hha_environment = {
//   production: false,
//   logoUrl: 'https://oxqusnbpamaqppeadzex.supabase.co/storage/v1/object/public/internaldesign/companylogo.png',
//   osbUrl: 'https://hhaosb.iq-motive.com',
//   portalUrl: '',
//   supabaseUrl: 'https://oxqusnbpamaqppeadzex.supabase.co',
//   supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyMjYzNTM1NiwiZXhwIjoxOTM4MjExMzU2fQ.LeBqbiPi72bY091l-Sne2RFeHCOeT5v7MShUrDhUpt8',
//   SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
//   currentVersion: 3
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
