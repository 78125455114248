import { HelperService } from '../../services/helper.service';
import { Vhcmodule } from '../vhcmodule';
import { SupaService } from '../../services/supa.service';
import { QbeditorDialog } from './qbeditor/qbeditor.dialog';
import { QbanswerDialog } from './qbanswer/qbanswer.dialog';
import { _ra } from '../../functions';
import { Part } from '../../interfaces/part.interface';
import { Job } from '../../interfaces/job.interface';

export class VhcQuestionblockModule extends Vhcmodule
{
    questions: VHCQuestion[] = [];
    variable=false;

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.name = 'Exterior';
        this.type = 'Questionblock';
    }


    async openEditor() {
        await this.loadSettings();

        if (this.name.startsWith('Maintenance - ')) {
            this.name = 'Maintenance. -' + this.name.substr(13);
        }

        const {data} = await this.helper.createAndOpenModal(QbeditorDialog, {
            name: this.name+'',
            name2: this.name2+'',
            variable: this.variable,
            settings: this.settings,
            questions: [...this.questions]
        }, 'fixed-modal tall-modal');

        if (data) {
            this.name = data.name;
            this.name2 = data.name2;
            this.variable = data.variable;
            this.questions = data.questions;
        }
    }

    addVariableQuestion() {
        const title = this.helper.promptInput('Please enter a question', 'Name', '', (v)=>{
            this.questions.push({
                answers:[],
                dateAuthorised:'',
                images:[],
                audio:[],
                name:v,
                name2:v,
                recommendation:'',
                recommendation2:'',
                value:0,
                videos:[],
            });
        });
    }

    async openAnswer(qidx) {
        await this.loadSettings();

        const {data} = await this.helper.createAndOpenModal(QbanswerDialog, {
            question: this.questions[qidx],
            settings: this.settings
        }, 'doubled-modal tall-modal');

        return data;
    }

    getName() {
        return this.name + ' ('+this.questions.length+')';
    }

    getPdfVersion(param: any=undefined,primarylang=false) {
        const d = {
            // layout: 'lightHorizontalLines', // optional
            table: {
                headerRows: 1,
                //       name, g, a,  r, grey, report, price, auth
                widths: [120, 15, 15, 15, 15, '*', 55, 26],
                body: [
                    [{ text: primarylang ? this.name : _ra(this.name2), fillColor: 'black', color: 'white', bold: true }, { text: '', fillColor: '#02a786' }, { text: '', fillColor: '#fcf002' }, { text: '', fillColor: '#e22426' }, { text: '', fillColor: '#efefef' }, primarylang ? 'Report & recommendations' : _ra('التقارير و التوصيات'), primarylang ? 'Fitted price' : _ra('السعر'), primarylang ? 'Auth' : _ra('التعميد')],
                    ...this.questions
                        .filter(f => (param==undefined)||(param != undefined && (f.value == 1 || f.value == 2)))
                        .map(d => {
                            const images = d.images ? d.images.map(i => ({image: i, fit: [180,200]})) : [];
                            const recommend = [(primarylang ? d.recommendation : _ra(d.recommendation2)),...images];
                            return [primarylang ? d.name : _ra(d.name2), { text: (d.value==3?'X':''), fillColor: '#02a786' }, { text:  (d.value==2?'X':''), fillColor: '#fcf002' }, { text:  (d.value==1?'X':''), fillColor: '#e22426' }, { text:  (d.value==0?'X':''), fillColor: '#efefef' }, recommend, '', (param != undefined ? 'X' : '')];
                        })
                ]
            },
            margin: [0, 0, 0, 20]
        };
        return d;
    }

    getHtmlVersion(param: any=false,primarylang=false) {
        let htmlBody = `<table class="striped" style="border: 1px solid #d2d6dd;">`;
        htmlBody += `
        <thead><tr>
            <th width="330">${primarylang ? this.name : this.name2}</th>
            <th width="25">-</th>
            <th>${primarylang ? 'Report & recommendations' : 'التقارير و التوصيات'}</th>
            <th width="90">${primarylang ? 'Price' : 'السعر'}</th>
            <th width="50">${primarylang ? 'Auth' : 'التعميد'}</th>
        </tr></thead><tbody>
        `;
        let atleastone = false;
        this.questions.forEach((q,i) => {
            if (q.dateAuthorised || param === false) {
                atleastone = true;
                htmlBody += `
                <tr style="border-top: 1px solid #d2d6dd;">
                    <td>
                    ${primarylang ? q.name : q.name2}
                    </td>
                    <td style="background-color: #${q.value==3 ? '02a786' : (q.value == 2 ? 'fcf002' : (q.value == 1 ? 'e22426' : '607d8b'))};"></td>
                    <td>
                    ${primarylang ? q.recommendation : q.recommendation2}
                    ${q.images.map(im => '<br /><img style="max-height: 150px" src="'+im+'" />').join('')}
                    </td>
                    <td style="border-left: 1px solid #607d8b;border-right: 1px solid #607d8b;text-align: right" class="price">${this.helper.tax(q.price, 0)}</td>
                    <td>${q.dateAuthorised?'🔑':''}</td>
                </tr>
                `;
            }
        });
        htmlBody += `</tbody></table><br />`;
        return atleastone ? htmlBody : '';;
    }

    public toDatabase(includedata=false) {
        return {name: this.name, name2: this.name2, questions: this.questions, type: this.type, variable: this.variable};
    }

    public fromDatabase(obj, includedata=false) {
        this.name = obj.name;
        this.name2 = obj.name2;
        this.variable = obj.hasOwnProperty('variable') ? obj.variable : false;
        this.questions = obj.questions;
        if (includedata) {
            this.questions.forEach((v,i)=>{
                if (!v.hasOwnProperty('value')) {
                    v.value = 0;
                }
                if (!v.hasOwnProperty('recommendation')) {
                    v.recommendation = '';
                }
                if (!v.hasOwnProperty('recommendation2')) {
                    v.recommendation2 = '';
                }
                if (!v.hasOwnProperty('images')) {
                    v.images = [];
                }
                if (!v.hasOwnProperty('videos')) {
                    v.videos = [];
                }
            });
        }
    }

    public getQIcon(value) {
        if (value == 3) {
            return 'green-text';
        }
        if (value == 1) {
            return 'red-text';
        }
        if (value == 2) {
            return 'amber-text';
        }
        return 'grey-text';
    }

    // VHC RECORD PART
    //q value, 0=grey, 1=red,2=amber,3=green
}

export interface VHCQuestion {
    name: string;
    name2: string;
    maintenancenote?: string;
    answers: string[][];
    value?: number;
    images?: string[];
    videos?: string[];
    audio?: string[];
    recommendation?: string;
    recommendation2?: string;
    dateAuthorised: string;
    auth?: boolean;
    portalid?: string;

    // In parts & jobs step
    parts?: Part[];
    jobs?: Job[];
    price?: number;
    sundries?: string|number;
}
