import { _ra } from "../../functions";
import { HelperService } from "../../services/helper.service";
import { InputField } from "../../services/new-edit.dialog";
import { SupaService } from "../../services/supa.service";
import { Vhcmodule } from "../vhcmodule";

export class VhcInputModule extends Vhcmodule {
    
    data = {
        visibleForCustomer: true,
        isCheckbox: false,
        answer:undefined
    }

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = "Input";
        this.name = "Your question here";
    }

    getName() {
        return this.name;
    }

    async openAnswer() {
        await this.loadSettings();

        if (this.data.isCheckbox) {
            return this.helper.createAndOpenAlert('Input module', this.name, (a)=>{
                this.data.answer = a;
            }, [
                {
                    name: 'no',
                    type: 'radio',
                    label: 'No',
                    value: false,
                    checked: !this.data.answer
                },{
                    name: 'yes',
                    type: 'radio',
                    label: 'Yes',
                    value: true,
                    checked: !!this.data.answer
                },
            ])
        } else {
            return this.helper.promptInput('Input module', this.name, this.data.answer, (a)=>{
                this.data.answer = a;
            })
        }
    }

    public getIcon() :string {
        return this.data.answer ? 'checkbox-outline' : 'square-outline'
    }

    public async openEditor(): Promise<void> {
        await this.loadSettings();

        let inputarr: InputField[] = [{ type: 'text', label: 'Change the display name ('+this.settings.lang1name+')', value: this.name, name: 'name' }]
        if (this.settings.lang2enabled) {
            inputarr.push({ type: 'text', label: 'Change the display name ('+this.settings.lang2name+')', value: this.name2, name: 'name2' });
        }        
        inputarr.push({ type: 'boolean', label: 'Display checkbox instead?', value: this.data.isCheckbox, name: 'isCheckbox' });
        inputarr.push({ type: 'boolean', label: 'Visible to the customer?', value: this.data.visibleForCustomer, name: 'visibleForCustomer' });

        let {data} = await this.helper.presentNewEditDialog('Input name', inputarr)
        console.log(data);
        if (data) {
            this.name = data.data.name;
            this.name2 = data.data.name2;
            this.data.isCheckbox = data.data.isCheckbox;
            this.data.visibleForCustomer = data.data.visibleForCustomer;
        }
    }

    getHtmlVersion(param:any=undefined,primarylang=false) {
        if (!this.data) {
            return `
            <div class="row">
                <span class="col">${primarylang ? this.name : this.name2}</span>
                <input class="col"></input>
            </div>
            `;
        }
        return `
        <div class="row">
            <span class="col">${primarylang ? this.name : this.name2}</span>
            ${this.data.isCheckbox ? (this.data.answer ? '&nbsp; ✅ &nbsp;' : '&nbsp; ❌ &nbsp;') : '<input class="col" readonly value="'+(this.data.answer?this.data.answer:'')+'"></input>'}
        </div>
        `;
    }

    getPdfVersion(param:any=undefined,primarylang=false) {
        return {
            table: {
                widths: [120, '*'],
                body: [
                    [primarylang ? this.name : _ra(this.name2), { text: '', fillColor: "#efefef", margin: 10 }]
                ],
            },
            margin: [0, 0, 0, 10],
            layout: 'noBorders'
        }
    }

    public toDatabase(includedata=false) {
        return {name: this.name, name2: this.name2, data: this.data, type: this.type, dateAuthorised: this.dateAuthorised}
    }
    
    public fromDatabase(obj, includedata=false) {
        this.name = obj.name
        this.name2 = obj.name2
        this.data = obj.data;
        this.dateAuthorised = obj.dateAuthorised
    }
}