/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import * as annyang from 'annyang';
import { Annyang } from 'annyang';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpeachService {

  CommandObservable = new Subject<string>();

  constructor() {
    console.log('INIT SPEACH SERVICE');

    const command = {
      'Open MPI': ()=>{
        this.CommandObservable.next(this.getDate() + 'Open MPI');
      },
      'Open Precheck': ()=>{
        this.CommandObservable.next(this.getDate() + 'Open Pre Check');
      },
      'Open Current Step': ()=>{
        this.CommandObservable.next(this.getDate() + 'Open Current Step');
      },
      'Open Vehicle': ()=>{
        this.CommandObservable.next(this.getDate() + 'Open Vehicle');
      },

      'Next': ()=>{
        this.CommandObservable.next(this.getDate() + 'Next');
      },
      'Red': ()=>{
        this.CommandObservable.next(this.getDate() + 'Red');
      },
      'Amber': ()=>{
        this.CommandObservable.next(this.getDate() + 'Amber');
      },
      'What can I do': ()=>{
        this.CommandObservable.next(this.getDate() + 'What can I do');
      },
      'Green': ()=>{
        this.CommandObservable.next(this.getDate() + 'Green');
      },
      'Recommendation': ()=>{
        this.CommandObservable.next(this.getDate() + 'Recommendation');
      },
      'Add Image': ()=>{
        this.CommandObservable.next(this.getDate() + 'Add Image');
      },
      'Add Video': ()=>{
        this.CommandObservable.next(this.getDate() + 'Add Video');
      },
      'Save and Close': ()=>{
        this.CommandObservable.next(this.getDate() + 'Save and Close');
      },


    };
    (annyang as Annyang).debug();
    (annyang as Annyang).addCommands(command);
    (annyang as Annyang).setLanguage('en');
    (annyang as Annyang).start();

    console.log(annyang);
    this.CommandObservable.subscribe(v => {
      console.log(v);
    });

  }

  private getDate() {
    return (new Date() as any)/1;
  }
}
