/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AlertController, AlertInput, LoadingController, ModalController, PickerController, ToastController } from '@ionic/angular';
import { InputField, NewEditDialog } from './new-edit.dialog';
import { FileDisplayItem } from '../components/fs/filedisplay/filedisplay.component';
import { BlueprintchooseDialog } from '../pages/vhcrecord/blueprintchoose/blueprintchoose.dialog';
import { vhcrecord } from '../interfaces/vhcrecord.interface';
import { SupaService } from './supa.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { marked } from 'marked';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { OsbAppointment, OsbJob } from '../pages/home/home.component';
import { VhcCross_sellModule } from '../vhcmodules/cross_sellmodule/cross_sellmodule';
import { UserSearchDialog } from '../components/usersearchdialog/usersearch.component';
import { ConfirmDelete } from '../decorators/confirmdelete.decorator';
import { VhcMessage } from '../pages/messages/messages.component';
import { IqdatepickerComponent } from '../components/iqdatepicker/iqdatepicker.component';
import { DateTime } from 'luxon';
import { Lightbox } from 'ngx-lightbox';
import { TaxPipe } from '../pipes/tax.pipe';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
      private toastController: ToastController,
      private alertController: AlertController,
      private modalController: ModalController,
      private loadingController: LoadingController,
      private router: Router,
      private supa: SupaService,
      private _location: Location,
      private pickerController: PickerController,
      private translate: TranslateService,
      private lightbox: Lightbox,
      private taxpipe: TaxPipe
    ) {
    console.log('INIT H Service');
  }

  public static RtlLangs = ['ar','arc','ckb','dv','fa','ha','he','khw','ks','ps','sd','ur','yi'];
  public get IsRtlSubject(): BehaviorSubject<boolean> {
    const newObservable = new BehaviorSubject(HelperService.RtlLangs.includes(this.translate.currentLang));
    this.translate.onLangChange.subscribe(ev => newObservable.next(HelperService.RtlLangs.includes(ev.lang)));
    return newObservable;
  }

  public tax(value: number, tax: number, taxonly: boolean = false) {
    const r = this.taxpipe.transform(value, tax, taxonly);
    return r === '0.00' ? '-' : r;
  }

  public back(): void {
    this._location.back();
  }

  public goto(url: string): void {
    this._location.back();
  }

  public markdownToHtml(md: string) {
    if (md && md.length > 0) {
      return marked(md).replace(/<a href=\"/g, '<a target="_blank" href=\"');
    }
    return md;
  }

  async notify(msg, color: PredefinedColors = 'primary', duration = 2000) {
    const toast = await this.toastController.create({
      message: msg,
      duration,
      color
    });
    toast.present();
  }

  public getUUID() {
    return uuidv4();
  }

  public async vinToFolder(vin: string): Promise<string> {
    const range = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const VIN = vin.toUpperCase();

    const loading = await this.loadingController.create({
      message: 'Loading vind couplers',
      spinner: 'bubbles',
      backdropDismiss: true
    });
    await loading.present();
    const {data, error} = await this.supa.supabase.from('vincoupler').select('*').order('sort');
    loading.dismiss();
    if (error) {
      console.log(error);
      this.notify('Could not load vin couplers', 'danger');
      return '';
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/naming-convention
    for(let v_idx = 0; v_idx < data.length; v_idx++) {
      const filter = data[v_idx].vinfilter;
      let f_index = 0;
      let v_index = 0;
      while(f_index<filter.length) {
        if (filter[f_index]==='[') {
          if (!range.substring(
              range.indexOf(filter[f_index+1]),
              range.indexOf(filter[f_index+3],
              range.indexOf(filter[f_index+1])
            )+1)
            .includes(
              VIN.charAt(v_index)
            )
          )
          {
            break;
          }
          f_index+=5;
        } else if (filter[f_index]!=='*') {
          if(VIN.charAt(v_index)!==filter[f_index]) {
            break;
          }
          f_index++;
        } else {
          f_index++;
        }
        v_index++;
      }
      if(f_index === filter.length) {
        // matches the filter
        return data[v_idx].folder;
      }
    }

    return '';
  }

  public async saveToLog(code: string, note: string) {
    const loading = await this.loadingController.create({
      message: 'Saving data',
      spinner: 'bubbles',
      backdropDismiss: true
    });
    await loading.present();
    await this.supa.supabase.from('iqlog').insert({code, note});
    loading.dismiss();
  }

  public semiSafePass(): string {
    return (Math.random() * 10000000).toString(36)
      + (Math.random() * 10000000).toString(36)
      + (Math.random() * 10000000).toString(36)
      + (Math.random() * 10000000).toString(36);
  }

  public async promptYesNo(title: string, header: string, okHandler: Function, okText: string = 'Ok', cancelText: string = 'Cancel'): Promise<boolean> {
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: () => { okHandler(); }
        }
      ]
    });

    await alert.present();
    const ans = await alert.onDidDismiss();
    return !(ans.role == 'backdrop' || ans.role == 'cancel');
  }

  public async getLoader() {
    return this.loadingController.create({
      spinner: 'bubbles',
      duration: 0,
      mode: 'ios',
      message: 'Loading, please wait...',
      backdropDismiss: true
    });
  }

  public async assignEmployeeToArray(locationcode: string, array: any[] = []): Promise<UserIdName|undefined> {
    const result = await this.employeePicker(locationcode);
    if (result) {
      const id = result ? result.id : '';
      const name = result ? result.name : '';
      const idx = array.findIndex(u => u.id == id+'');
      if (idx == -1) {
        this.notify('Adding ' + name);
        array.push({id, name});
        return {id, name};
      } else {
        this.notify('This employee is already assigned');
      }
    }
    return undefined;
  }

  public async employeePicker(locationcode: string): Promise<UserIdName|undefined> {
    const result = await this.createAndOpenModal(UserSearchDialog, {locationcode},'medium-modal doubled-modal');
    if (result.data?.user) {
      return {id: result.data?.user.id, name: result.data?.user.fullname};
    }
    return undefined;
  }

  public async chooseDateTime(params: DatePickParams): Promise<DateTime|DateTime[]> {
    const modal = await this.modalController.create({
      component: IqdatepickerComponent,
      mode: 'md',
      cssClass: 'doubled-modal',
      componentProps: params,
    });

    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data) {
      if (data.date) {
        return data.date;
      }
    }
    return params.IsRange ? [params.RangeStart, params.RangeEnd] : params.SingleDate;
  }

  public async promptInput(title: string, header: string, inputValue:any = '', okHandler: Function, inputPlaceholder = 'Please answer this question', inputType: TextFieldTypes | 'checkbox' | 'radio' | 'textarea' = 'text', okText: string = 'Ok', cancelText: string = 'Cancel'): Promise<boolean> {
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      inputs: [
        {
          name: 'theinput',
          attributes: {
            autoComplete: 'off'
          },
          type: inputType,
          value: inputValue,
          placeholder: inputPlaceholder,
        }
      ],
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: (q) => { okHandler(q.theinput); }
        }
      ]
    });

    await alert.present();
    const ans = await alert.onDidDismiss();
    return !(ans.role == 'backdrop' || ans.role == 'cancel');
  }

  public async presentNewEditDialog(title: string, inputFields: InputField[], deletecategory: FileDisplayItem = undefined, size: newEditDialogSizes = '', customDescription: string = undefined): Promise<OverlayEventDetail<any>> {
    const modal = await this.modalController.create({
      component: NewEditDialog,
      mode: 'ios',
      cssClass: size,
      componentProps: {
        title,
        inputFields,
        ondelete: deletecategory,
        customDescription
      }
    });

    await modal.present();
    return modal.onDidDismiss();
  }

  public async createAndOpenAlert(title: string, header: string, okHandler: Function, inputs: AlertInput[], okText: string = 'Ok', cancelText: string = 'Cancel', cssClass = ''): Promise<boolean>
  {
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      inputs,
      cssClass,
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: (q) => { okHandler(q); }
        }
      ]
    });

    await alert.present();
    const ans = await alert.onDidDismiss();
    return !(ans.role == 'backdrop' || ans.role == 'cancel');
  }

  public async createAndOpenModal(componentRef: any, data: Object, cssClass: string = '', backdropDismiss=true): Promise<OverlayEventDetail<any>> {
    const modal = await this.modalController.create({
      component: componentRef,
      cssClass,
      mode: 'md',
      componentProps: data,
      backdropDismiss
    });

    await modal.present();
    return modal.onDidDismiss();
  }

  public async openLightBox(images: string[], initIndex = 0): Promise<void> {
    const _album = images.map((i,idx) => ({
        src: i,
        caption: 'Image ' + (idx+1),
        thumb: undefined
      }));
    this.lightbox.open(_album, initIndex, {
      showDownloadButton: true
    });
  }

  public async createAndOpenPicker(options: JPickerOption[], name: string, oktext='Confirm', canceltext='cancel') {
    const picker = await this.pickerController.create({
      animated: true,
      buttons: [{
        text: canceltext,
        role: 'cancel'
      }, {
        text: oktext,
        role: 'ok'
      }],
      columns: [
        {
          name,
          options
        }
      ],
      mode: 'md',
    });
    await picker.present();
    const { data } = await picker.onDidDismiss();
    return data ? data[Object.keys(data)[0]] : undefined;
  }

  public async createNewRecord(vin: string,appointment: OsbAppointment=undefined,helperref: HelperService=undefined,suparef: SupaService=undefined,connectedToDms=true) {
    this.notify('Creating a new vhc record');
    const settings = await this.supa.Settings();

    // Do some checks; is it allowed?

    const vehiclecheck = await this.supa.supabase.from('vhcrecord').select('id').eq('vin', vin).eq('isdone', false);
    if (vehiclecheck.error) {
      this.notify('There was an error while checking the vehicles records', 'danger');
      return;
    }
    if (vehiclecheck.data.length > 0) {
      this.notify('Please finish the current VHC record before creating a new one', 'primary');
      return;
    }

    // Choose the blueprint
    const blueprintchooser = await this.modalController.create({
      component: BlueprintchooseDialog,
      mode: 'ios',
      cssClass: 'big-modal'
    });

    await blueprintchooser.present();
    const result = await blueprintchooser.onDidDismiss();

    if (!result.data) {
      return;
    }

    // Retrieve blueprint
    const blueprintdata = await this.supa.supabase.from('vhcblueprint').select('*').eq('id', result.data.id).single();
    if (blueprintdata.error) {
      this.notify('There was an error while getting the blueprint', 'danger');
      return;
    }

    if (appointment && helperref && suparef) {
      const tax = settings.defaulttax + 100;

      const preselectedPackages: VhcCross_sellModule = new VhcCross_sellModule(helperref, suparef);
      preselectedPackages.name = 'OSB Booked items';
      preselectedPackages.data = [];
      appointment.jobs.forEach((appjob: OsbJob) => {
        let price = Number.parseFloat(appjob.price);
        price = Number.isNaN(price) ? 0 : price;
        price = Math.round(((price * 10000) / tax))/100;

        preselectedPackages.data.push({
            jobs: [
                {
                  name: appjob.name + '_' + appjob.operationCode,
                  name2: appjob.name + '_' + appjob.operationCode,
                  code: appjob.operationCode,
                  price,
                  hour: appjob.duration/60
                }
            ],
            parts: [],
            name: appjob.name,
            name2: appjob.name,
            sundriesprice: 0,
            price
        });
      });
      blueprintdata.data.premodules.unshift(preselectedPackages.toDatabase(true));
    }

    // Build data and store it

    let apptdmsid: string|null = null;
    if (appointment?.receptionist) {
      const regexresult = /[0-9]+/.exec(appointment.receptionist);
      if (regexresult) {
        if (regexresult.length > 0) {
          apptdmsid = regexresult[0];
        }
      }
    }
    let userresult: any;
    if (apptdmsid) {
      userresult = await this.supa.supabase.from('userpermissions').select('id,fullname').eq('employee_id',apptdmsid);
    }
    let founduser: any;
    if (userresult) {
      if (userresult.data) {
        if (userresult.data.length > 0) {
          founduser = {id: userresult.data[0].id, name: userresult.data[0].fullname};
        }
      }
    }

    const recordData: vhcrecord = {
      blueprintname: result.data.name,
      vin,
      mileage: 0,
      description: blueprintdata.data.description,
      disclaimer: blueprintdata.data.disclaimer,
      disclaimer2: blueprintdata.data.disclaimer2,
      fixedansweringorder: blueprintdata.data.fixedansweringorder,
      includepre: blueprintdata.data.includepre,
      includempi: blueprintdata.data.includempi,
      includejp: blueprintdata.data.includejp,
      includeauth: blueprintdata.data.includeauth,
      includeqi: blueprintdata.data.includeqi,
      premodules: blueprintdata.data.premodules,
      mpimodules: blueprintdata.data.mpimodules,
      qimodules: blueprintdata.data.qimodules,
      portalsettings: {
        expectedHours: 2,
        expectedMinutes: 30,
        includeFriday: true,
        includeMonday: true,
        includeSaturday: true,
        includeSunday: true,
        includeThursday: true,
        includeTuesday: true,
        includeWednesday: true,
        pickupHour: 16,
        pickupMinutes: 0
      },
      status: 'pre',
      authobjects: [],
      jlocation_code: appointment ? appointment.locationCode : '',
      organisationdata: {
        connectedToDms,
        requestedpreitems: false,
        gotpreitems: false,
        requestedmpiitems: false,
        gotmpiitems: false,
        iqmjc_id: '',
        appointmentref: appointment ? appointment.id : 0,
        serviceadvisor_id: apptdmsid+'',
        prepostresult: {}
      },
      assignees: founduser ? { pre: [founduser], mpi: [], jp: [], auth: [], qi: [], prejp :[], preauth:[] } : { pre: [], mpi: [], jp: [], auth: [], qi: [] , prejp : [], preauth:[]}
    };

    if (founduser) {
      this.notifyUser(founduser.id, `You were added to 'pre' in vhc record ${0}.`, `vhc-record;id=${0}`);
    }
    console.log(recordData);
    const newrecord = await this.supa.supabase.from('vhcrecord').insert(recordData).single();
    if (blueprintdata.error) {
      this.notify('There was an error while saving the blueprint', 'danger');
      return;
    }

    // Open the record
    this.router.navigate(['vhc-record', {id: newrecord.data.id}]);
  }

  public async notifyUser(userid, message, url) {
    await this.supa.supabase.from<VhcMessage>('vhcmessages').insert({message, url, userid});
  }

  public getDateTime() {
    const today = new Date();
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' ' + today.getHours().toString().padStart(2,'0') + ':' + today.getMinutes().toString().padStart(2,'0');
  }

  public checkEmail(email: string): boolean {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      this.notify('Please enter a valid email', 'dark');
      return false;
    }
    return true;
  }

}

export type newEditDialogSizes = 'big-modal' | 'medium-modal' | 'normal' | 'doubled-modal' | '';
export type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';
export type PredefinedColors = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';
export interface OverlayEventDetail<T = any> {
  data?: T;
  role?: string;
}

export interface JPickerOption {
  text: string;
  value: string;
}

export interface UserIdName {
  id: string;
  name: string;
}

export interface DatePickParams {
  RangeStart?: DateTime;
  RangeEnd?: DateTime;
  SingleDate?: DateTime;
  IsRange: boolean;
  WithTime: boolean;
}
